import React from "react"
import Header from "../Components/Header/Header"
import "../Home/home.css"


function Home() {
    return (
        <div>
            <Header />
            <div className="top-container">
                <div className="container intro-top">
                    <div className="textBlock mulish-ksports d-flex">
                        <div className="left-compartment">
                            <h1>Play Your
                                <br></br> Fantsay Game
                            </h1>
                            <h1 className="mt-1"> <strong>Support Your Community</strong></h1>
                        </div>
                        <div className="left-compartment">
                        </div>
                    </div>
                    <div></div>
                </div>
            </div>
        </div>
    )
}

export default Home