import React from "react"
import "../Header/header.css"
import { Link } from "react-router-dom"

function Header() {
    return (
        <div className="header">
            <div className="long-header">
                <div className="logo-text"><p>ksports</p></div>
                <div className="optiom-list">
                    <ul>
                        <li>
                            <Link to="about_us" className="toggle-option mulish-ksports"><p>ABOUT US</p></Link>
                        </li>
                        <li>CAREER</li>
                    </ul>
                </div>
            </div>
        </div>
    )

}

export default Header