import { Route, Routes } from "react-router-dom"
import AboutUs from "../About-us/About"
import Home from "../Home/Home"


export default function routes() {
    return (
        <Routes>
            <Route path='/' element={<Home />}></Route>
            <Route path='/about_us' element={<AboutUs/>}></Route> 
        </Routes>
    )
}