import './App.css';
// import { Route, Router, Routes } from 'react-router-dom';
import Routes from './routes/routes';
function App() {
  return (
    <Routes/>
  );
}

export default App;
